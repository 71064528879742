import { render, staticRenderFns } from "./StudentDetails.vue?vue&type=template&id=bf793eea&scoped=true&"
import script from "./StudentDetails.vue?vue&type=script&lang=js&"
export * from "./StudentDetails.vue?vue&type=script&lang=js&"
import style0 from "./StudentDetails.vue?vue&type=style&index=0&id=bf793eea&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bf793eea",
  null
  
)

export default component.exports